// Import the functions you need from the SDKs you need
//
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
////

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBlVH_MKtL6cUI8sr-3GWJ1DkCvURVeGe8",
  authDomain: "slack-clone-c64fb.firebaseapp.com",
  projectId: "slack-clone-c64fb",
  storageBucket: "slack-clone-c64fb.appspot.com",
  messagingSenderId: "81186001769",
  appId: "1:81186001769:web:4539ab1d534afd8abae3f8"
};

// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();

const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();

export {auth, provider, db };




